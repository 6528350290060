import s from "./HomeWorkPage.module.css";
import cn from "classnames";
import HeaderPage from "../../common/HeaderPage";
import ButtonOld from "../../UI/Button";
import Preloader from "../../UI/Preloader";
import { FrontTaskCardList } from "../../common/FrontTaskCard/FrontTaskCardList";
import { useScreenWidth } from "../../../hooks/useScreenWidth";
import { Select } from "antd";
import classNames from "classnames";
import { ButtonPrev } from "@UI/Button";
import { GetTitle } from '@/helpers/GetTitle';
import Button from '../../UI/Button'

const HomeWorkPage = (props) => {
  const { type, status, filters, tasks, reset, newDesign=false } = props;

  return (
    <>
      {
        !newDesign && <HeaderPage />
      }
      <main className={cn("page-content", s.page)}>
        {
          newDesign && <div style={{marginBottom: '20px', flexWrap:'wrap'}} className="newDesignHeader">
          <ButtonPrev noHistory={true} newDesign={true} />
          <div className="newDesignTitleText">{GetTitle()}</div>
          </div> 
        }
        <div className={classNames(s.headRow, s.tabs)}>
          <Tabs {...{ ...type, placeholder: "Тип дз", newDesign }} />
          <Tabs {...{ ...status, placeholder: "Статус", newDesign }} />
        </div>
        <Filters {...{ items: filters, reset, newDesign }} />

        {type.get !== "abstract" && status.get === "10" && (
          <ul className={s.listBooble}>
            <li>
              <span className={cn(s.booble, s.accent)}></span> - решено верно
            </li>
            <li>
              <span className={cn(s.booble, s.red)}></span> - решено неверно
            </li>
            {/* <li><span className={classNames(s.booble, s.yellow)}></span> - решено почти верно</li> */}
          </ul>
        )}

        {tasks.loadingAll ? (
          <Preloader fullScreen="center" />
        ) : tasks.empty ? (
          <p className="empty">{tasks.empty}</p>
        ) : (
         <> 
            <p className="backgroundNone backgroundNewYellow" style={{marginBottom:'20px', width:'fit-content'}}>Всего работ: {tasks?.totalCount}</p>
            <FrontTaskCardList
                refD={tasks.ref}
                loading={tasks.loading}
                works={tasks.get}
                role="children"
                type={type.get}
            />
         </>
        )}
      </main>
    </>
  );
};

export default HomeWorkPage;

const Tabs = (props) => {
  const isMobile = useScreenWidth(992);
  const items = [];
  for (const key in props.items)
    items.push({ name: props.items[key], label: props.items[key], value: key });

  if (isMobile)
    return (
      <Select
        {...{
          value: props.get,
          placeholder: props.placeholder,
          className: `${s.select} ${props?.newDesign ? 'antdSelectNewDesign' : ''}`,
          onChange: (e) => props.set(e),
          options: items,
        }}
      />
    );

  return (
    <div className={s.headRow}>
      {items.map(({ name, value }) => (
        <ButtonOld
          newDesign={props.newDesign} 
          newDesignYellow={props.newDesign && props.get === value}
          key={value}
          styles={cn({ yellow: props.get === value })}
          {...{ name, action: () => props.set(value) }}
        />
      ))}
    </div>
  );
};

const Filters = (props) => {
  const items = [];
  for (const key in props.items) items.push(props.items[key]);
  return (
    <div className={s.headRow}>
      {items.map((filter, i) => (
        <Select key={i} className={`${s.select} ${props?.newDesign ? 'antdSelectNewDesign antdSelectNewDesignFlexAutoMob' : ''}`} {...filter} />
      ))}
      <Button
        newDesign={props?.newDesign} 
        onClick={props.reset}
      >
        Сбросить
      </Button>
    </div>
  );
};
